
import * as Components from "./Mgrill"

export default function getCustomComponent () {
  return Components
}

export function getShopId () {
  return "mgrill"
}

