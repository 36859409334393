import React, { Component, PureComponent } from 'react';
import './index.scss'
import {NavLink, Link} from 'react-router-dom'
import AboutUsPage from './About'

import mobilecheck from './mobilecheck'

const IS_MOBILE = mobilecheck()

export const customOrderAppPaths = ["/", "/menu", "/order"]

export {AboutUsPage as about}


export const pages = [
  {path: "/order", label: "Order"},
  {path: "/menu", label: "Menu"},
  // {path: "/about", label: "Our Concept"},
  // {path: "/about", label: "Catering"}
]

export class SiteNav extends PureComponent {
  render() {

    return (
    <nav className="pages-nav">
      <ul>
        {pages.map(({path, label}) => (
          <li><NavLink className="page-link" exact to={path}>{label}</NavLink></li>
        ))}
      </ul>
    </nav>
  )}
}

class CoralCafe extends Component {
  render() {
    return (
      <div className="coralcafe">
      </div>
    );
  }
}

export class Header extends Component {
  render() {
    return (
      <div className="landing-page-wrapper">
        <div className="landing-page">
          <div className="text">
            <h2>MEDITERRANEAN GRILL</h2>
            <p className="catch-phrase">Free Delivery 2 miles, max 7 miles</p>
            <p>105 N Hill Ave</p>
            <p><a
              style={{
                color: 'white'
              }}
              href="tel:+16264496246">626 449 6246</a></p>
            {/* <p>since 1971</p> */}
            <Link to="/order" className="order-online-link">Order <br />Pickup & Delivery <br />5% Rewards</Link>
            {/* <p>5% Cashback</p> */}
          </div>








            {/* <article className="landing-section award">
              <div className="section-header">
                <div className="text">
                  <img src="https://d2abh92eaqfsaa.cloudfront.net/image/upload/v1551992456/Artboard_8latimes_aqnxze.png" alt=""/>
                </div>
              </div>
            </article> */}





        </div>
      </div>
    );
  }
}

export class OrderingHeader extends PureComponent {
  render() {
    return (
      <div className="ordering-header-wrapper">
        <div className="ordering-header">

          <div
            className="text"
             >
            <h2>Start your order below</h2>
            <p></p>
          </div>
        </div>
      </div>
    );
  }
}

export default CoralCafe;


export function subMenuFilter (subMenu) {
  const tag = subMenu.tag
  if (!tag) {return true}

  if (tag === 'hide') {
    return false
  }
  return true

}